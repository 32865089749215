<script setup lang="ts">
import dayjs from 'dayjs';
import { CalendarHolidayList } from 'types/flight';
import {
  ref,
  computed,
  useVModel,
  useCalendarConfig,
  useConfigStore,
  useI18n,
  useMasterService,
  useState,
} from '#imports';

interface ISharedCalendarPickerProps {
  modelValue: string[];
  id: string;
  minDate?: dayjs.ConfigType;
  maxDate?: dayjs.ConfigType;
  minDateDiff?: number;
  maxDateRange?: number;
  range?: boolean;
  pickEnd?: boolean;
  faresData?: {
    [key: string]: number;
  };
}

interface ISharedCalendarPickerEmits {
  (e: 'update:modelValue', value: string[]): void;
  (e: 'done'): void;
  (e: 'pickend'): void;
}

const props = withDefaults(defineProps<ISharedCalendarPickerProps>(), {
  minDateDiff: 1,
  maxDateRange: 0,
  minDate: dayjs().format('YYYY-MM-DD'),
  maxDate: dayjs().add(1, 'year').format('YYYY-MM-DD'),
});
const emit = defineEmits<ISharedCalendarPickerEmits>();

const { locale } = useI18n();
const config = useConfigStore();
const { getFares } = useCalendarConfig();

const { data: holidayList } = useAsyncData(
  () => useMasterService().getHolidayList({ country: config.country!, lang: locale.value }),
  {
    transform: (data) => data.result,
  },
);

const isPickEnd = ref(Boolean(props.range && props.pickEnd && props.modelValue[0]));
// const selectedDates = ref(props.modelValue.slice());

const dateCtx = useState(`calendar-date-ctx-${props.id}`, () => dayjs(props.modelValue[0]));

const selectedDates = useVModel(props, 'modelValue', emit);

const calendars = computed(() => {
  return Array.from({ length: 2 }, (_, k) => {
    const dateOfMonth = dateCtx.value.add(k, 'month');
    const yearOfMonth = dateOfMonth.year();
    const holidayOfMonth = holidayList.value
      ?.filter(
        (day) => dayjs(day.date).get('month') === dateOfMonth.month() && dayjs(day.date).get('year') === yearOfMonth,
      )
      .reduce((holiday, { date, name }) => {
        holiday[date as keyof typeof holiday] = name;
        return holiday;
      }, {} as CalendarHolidayList);

    return { dateCtx: dateOfMonth, month: dateOfMonth.month(), year: yearOfMonth, holiday: holidayOfMonth };
  });
});

const navigationDisabled = computed(() => ({
  prev: calendars.value[0]!.dateCtx.isSame(props.minDate, 'month'),
  next: calendars.value[calendars.value.length - 1]?.dateCtx.isSame(props.maxDate, 'month'),
}));

function navigateCalendar(dir: 'prev' | 'next') {
  if (dir === 'prev') {
    dateCtx.value = dateCtx.value.subtract(1, 'month');
  } else {
    dateCtx.value = dateCtx.value.add(1, 'month');
  }
}

function onSelectDate(newDate: string) {
  if (!props.range) {
    selectedDates.value = [newDate];

    return;
  }

  if (isPickEnd.value) {
    if (dayjs(newDate).isBefore(selectedDates.value[0])) {
      selectedDates.value = [newDate];
    } else {
      selectedDates.value[1] = newDate;
    }
  } else {
    if (dayjs(newDate).isAfter(selectedDates.value[1])) {
      selectedDates.value = [newDate];
    } else {
      selectedDates.value[0] = newDate;
    }

    isPickEnd.value = true;
    emit('pickend');
  }
}

function onConfirm() {
  // emit('update:modelValue', selectedDates.value);
  emit('done');
}
</script>

<template>
  <div class="relative">
    <div class="relative flex gap-x-20 px-10 pb-20">
      <SharedCalendar
        v-for="(calendar, index) in calendars"
        :id="`calendar_${index}`"
        :key="`${calendar.month}_${calendar.year}`"
        :selected-dates="selectedDates"
        :month="calendar.month"
        :year="calendar.year"
        :holiday="calendar.holiday"
        :fares="getFares(calendar, props.faresData)"
        :min-date="minDate"
        :max-date="maxDate"
        class="flex-1"
        @select="onSelectDate"
      />
    </div>

    <div class="absolute top-0 left-0 right-0 p-10 flex justify-between">
      <ApzButton
        variant="clear"
        class="w-[30px] !h-[30px]"
        data-testid="flightSearch-prevMonth-button"
        :disabled="navigationDisabled.prev"
        @click="navigateCalendar('prev')"
      >
        <ApzIcon :icon="['far', 'chevron-left']" />
      </ApzButton>
      <ApzButton
        variant="clear"
        class="w-[30px] !h-[30px]"
        data-testid="flightSearch-nextMonth-button"
        :disabled="navigationDisabled.next"
        @click="navigateCalendar('next')"
      >
        <ApzIcon :icon="['far', 'chevron-right']" />
      </ApzButton>
    </div>

    <div class="flex justify-end px-20 py-15 border-gray-light border-t-[0.5px]">
      <ApzButton
        type="primary"
        class="px-30 py-15"
        data-testid="done-calendar-button"
        @click="onConfirm"
      >
        {{ $t('global.calendarsearchbutton') }}
      </ApzButton>
    </div>
  </div>
</template>
